import { USER_LOGIN_SUCCEEDED, USER_LOGIN_FAILED, USER_LOGOUT_REQUESTED, ACCOUNT_INFO_SUCCEEDED } from '../actions/authentication'

export default function authentication(state={token: null, accountInfo: null}, action) {
    switch (action.type) {
        case USER_LOGIN_SUCCEEDED:
            return Object.assign({}, state, {
                token: action.payload
            })
        case USER_LOGIN_FAILED:
            alert(action.payload)
            return state
        case USER_LOGOUT_REQUESTED:
            return Object.assign({}, state, {
                token: null,
                accountInfo: null
            })
        case ACCOUNT_INFO_SUCCEEDED:
            return Object.assign({}, state, {
                accountInfo: action.payload
            })
        default:
            return state
    }
}