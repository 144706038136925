import { HereMapsClient } from "@silberistgold/orbit-kit";
import { cities } from "../cities";
import { AdminOrderAction } from "./AdminOrderAction";
import { APIUrl, hereApiKey } from "./constants";
import { Order } from "./Order";
import { Page } from "./page";
import { Rating } from "./Rating";

const client = new HereMapsClient(hereApiKey);

export function login(email: string, password: string) {
  const url = `${APIUrl}/orders/admins/login`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ email, password }),
  }).then((res) => {
    if (res.ok) return res.json();
    throw new Error("Network response error.");
  });
}

export function fetchAccountInfo(token: string) {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/admins/self`;
  return fetch(url, {
    headers: headers,
  }).then((res) => {
    if (res.ok) return res.json();
    throw new Error("Network response error.");
  });
}

export const fetchOrder = async (
  token: string,
  orderId: string
): Promise<Order> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/admins/orders/${orderId}`;
  const res = await fetch(url, {
    headers: headers,
  });
  if (!res.ok) {
    throw new Error("Network response error.");
  }
  return res.json();
};

export const orderAction = async (
  token: string,
  orderId: string,
  action: AdminOrderAction
): Promise<Order> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/admins/orders/${orderId}?action=${action}`;
  const res = await fetch(url, {
    headers: headers,
    method: "PUT",
  });
  if (!res.ok) {
    try {
      const jsonError = await res.json();
      throw { isJson: true, payload: jsonError };
    } catch (error) {
      if ((error as any).isJson) throw (error as any).payload;
      throw new Error(`Response Status ${res.status} (${res.statusText})`);
    }
  }
  return res.json();
};

export const editOrder = async (
  token: string,
  updateValues: any
): Promise<Order> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/admins/orders/update`;
  const res = await fetch(url, {
    headers: headers,
    method: "POST",
    body: JSON.stringify(updateValues),
  });

  if (!res.ok) {
    try {
      const jsonError = await res.json();
      throw { isJson: true, payload: jsonError };
    } catch (error) {
      if ((error as any).isJson) throw (error as any).payload;
      throw new Error(`Response Status ${res.status} (${res.statusText})`);
    }
  }
  return res.json();
};

export function fetchDrivers(token: string) {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  return Promise.all(
    cities.map((city) => {
      const url = `${APIUrl}/orders/admins/drivers?city=${city}`;
      return fetch(url, {
        headers: headers,
      }).then((res) => {
        if (res.ok) return res.json();
        throw new Error("Network response error.");
      });
    })
  ).then((responses) => {
    console.log(responses);
    return responses.flatMap((e) => e.drivers);
  });
}

export async function fetchRatings(
  token: string,
  driverId: string
): Promise<Rating[]> {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/ratings/driver/${driverId}`;
  const res = await fetch(url, {
    headers: headers,
  });
  if (!res.ok) {
    throw new Error("Network response error.");
  }
  const data = await res.json();
  return data;
}

export function sendNotificationToDriver(
  token: string,
  driverId: string,
  message: string
) {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/admins/drivers/${driverId}/notification`;
  return fetch(url, {
    headers: headers,
    method: "POST",
    body: JSON.stringify({ message }),
  }).then((res) => {
    if (res.ok) return res;
    throw new Error("Network response error.");
  });
}

export function addressCompletion(query: string) {
  return client
    .autosuggest({
      q: query,
      at: `50.9098,10.4034`,
      in: ["countryCode:DEU"],
      lang: ["de"],
      limit: 20,
      show: ["streetInfo"],
    })
    .then((res) => {
      const filtered = res.items.map((r) =>
        Object.assign({}, r, { valid: r.resultType === "houseNumber" })
      );
      return filtered;
    });
}

export function fetchPage<T>(
  token: string,
  urlPath: string,
  cursor?: string
): Promise<Page<T>> {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = `${APIUrl}/orders/${urlPath}${
    !!cursor ? "?after=" + cursor : ""
  }`;
  return fetch(url, {
    headers: headers,
  }).then((res) => {
    if (res.ok) return res.json();
    throw new Error("Network response error.");
  });
}
