import {
  DRIVER_LIST_REQUESTED,
  DRIVER_LIST_SUCCEEDED,
  DRIVER_LIST_FAILED,
  DRIVER_SEND_MESSAGE_REQUESTED,
  DRIVER_SEND_MESSAGE_SUCCEEDED,
  DRIVER_SEND_MESSAGE_FAILED
} from "../actions/drivers";

export default function drivers(
  state = {
    driverList: [],
    driverListLoading: false,
    sendMessageLoading: false
  },
  action
) {
  switch (action.type) {
    case DRIVER_LIST_REQUESTED:
      return Object.assign({}, state, {
        driverListLoading: true
      });
    case DRIVER_LIST_SUCCEEDED:
      return Object.assign({}, state, {
        driverList: action.payload,
        driverListLoading: false
      });
    case DRIVER_LIST_FAILED:
      return Object.assign({}, state, {
        driverListLoading: false
      });
    case DRIVER_SEND_MESSAGE_REQUESTED:
      return Object.assign({}, state, {
        sendMessageLoading: true
      });
    case DRIVER_SEND_MESSAGE_SUCCEEDED:
    case DRIVER_SEND_MESSAGE_FAILED:
      return Object.assign({}, state, {
        sendMessageLoading: false
      });
    default:
      return state;
  }
}
