import { call, put, takeLatest } from 'redux-saga/effects'
import { login, fetchAccountInfo } from '../lib/api'
import { USER_LOGIN_REQUESTED, ACCOUNT_INFO_REQUESTED, successfullUserLogin, failedUserLogin, successfullAccountInfo, failedAccountInfo } from '../actions/authentication'

function* loginWorker(action) {
    try {
        const response = yield call(login, action.payload.email, action.payload.password)
        yield put(successfullUserLogin(response.token))
    } catch (e) {
        yield put(failedUserLogin(e.message))
    }
}

export function* loginSaga() {
    yield takeLatest(USER_LOGIN_REQUESTED, loginWorker)
}

function* accountInfoWorker(action) {
    try {
        const response = yield call(fetchAccountInfo, action.payload)
        yield put(successfullAccountInfo(response))
    } catch (e) {
        yield put(failedAccountInfo(e.message))
    }
}

export function* accountInfoSaga() {
    yield takeLatest(ACCOUNT_INFO_REQUESTED, accountInfoWorker)
}