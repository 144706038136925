export const CONFIRM_ORDER_REQUESTED = "CONFIRM_ORDER_REQUESTED";
export const CONFIRM_ORDER_SUCCEEDED = "CONFIRM_ORDER_SUCCEEDED";
export const CONFIRM_ORDER_FAILED = "CONFIRM_ORDER_FAILED";

export function confirmOrder(token, orderId) {
  return {
    type: CONFIRM_ORDER_REQUESTED,
    payload: {
      token,
      orderId,
    },
  };
}

export function successfullConfirmOrder() {
  return {
    type: CONFIRM_ORDER_SUCCEEDED,
  };
}

export function failedConfirmOrder(message) {
  return {
    type: CONFIRM_ORDER_FAILED,
    payload: message,
  };
}

export const RESEND_OFFER_DRIVERS_REQUESTED = "RESEND_OFFER_DRIVERS_REQUESTED";
export const RESEND_OFFER_DRIVERS_SUCCEEDED = "RESEND_OFFER_DRIVERS_SUCCEEDED";
export const RESEND_OFFER_DRIVERS_FAILED = "RESEND_OFFER_DRIVERS_FAILED";

export function resendDriversOffer(token, orderId) {
  return {
    type: RESEND_OFFER_DRIVERS_REQUESTED,
    payload: {
      token,
      orderId,
    },
  };
}

export function successfullResendDriversOffer() {
  return {
    type: RESEND_OFFER_DRIVERS_SUCCEEDED,
  };
}

export function failedResendDriversOffer(message) {
  return {
    type: RESEND_OFFER_DRIVERS_FAILED,
    payload: message,
  };
}

export const EDIT_ORDER_REQUESTED = "EDIT_ORDER_REQUESTED";
export const EDIT_ORDER_SUCCEEDED = "EDIT_ORDER_SUCCEEDED";
export const EDIT_ORDER_FAILED = "EDIT_ORDER_FAILED";

export function editOrder(token, updateValues) {
  return {
    type: EDIT_ORDER_REQUESTED,
    payload: {
      token,
      updateValues,
    },
  };
}

export function successfullEditOrder(updatedOrder) {
  return {
    type: EDIT_ORDER_SUCCEEDED,
    payload: updatedOrder,
  };
}

export function failedEditOrder(message) {
  return {
    type: EDIT_ORDER_FAILED,
    payload: message,
  };
}
