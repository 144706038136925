export const readableStory = story => {
    if (story === 0) {
        return 'Erdgeschoss'
    } else if (story < 0) {
        return `${Math.abs(story)}. Untergeschoss`
    } else {
        return `${story}. Obergeschoss`        
    }
}

export const readableMoveType = type => {
    if (type === 'drive_only') return 'Bordsteinfahrt'
    else if (type === 'move_one_helper') return 'Fahrer hilft'
    else if (type === 'move_two_helpers') return 'Zwei Helfer'
    else return "UNDEFINIERTER TYP"
}

export const allMoveTypes = [
    'drive_only', 'move_one_helper', 'move_two_helpers'
]