import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment-timezone";
import "moment/locale/de";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Row,
  Col,
} from "reactstrap";
import {
  requestDriverList,
  requestSendDriverMessage,
} from "../../actions/drivers";
import { DriverOrderStatistics } from "../DriverOrderStatistics";
import { DriverRatingsList } from "../DriverRatingsList";

moment.locale("de");

const detailContentForDriver = (driver, toggleMessageModalHandler) => (
  <div>
    <Row className="justify-content-between">
      <Col xs="auto">
        <h2>Details für Fahrer {driver.name}</h2>
      </Col>
      <Col xs="auto">
        <ButtonGroup>
          <Button outline color="primary" onClick={toggleMessageModalHandler}>
            Nachricht senden
          </Button>
        </ButtonGroup>
      </Col>
      <Col xs="12">
        <div className="separator" />
      </Col>
    </Row>
    <Row>
      <Col xs="12" sm="6">
        <span>E-Mail</span>
        <h5>
          <a href={`mailto:${driver.email}`}>{driver.email}</a>
        </h5>
        <span>Telefon</span>
        <h5>
          <a href={`tel:${driver.phone}`}>{driver.phone}</a>
        </h5>
        <span>Stadt</span>
        <h5>{driver.city}</h5>
      </Col>
      <Col xs="12" sm="6">
        <span>Account ID</span>
        <h5>{driver.id}</h5>
        <span>Registrierte Geräte</span>
        {(driver.push_tokens || []).map((t) => (
          <h5 key={t.device_id}>
            Art: {t.type}
            <br />
            Device ID: {t.device_id}
          </h5>
        ))}
      </Col>
    </Row>
  </div>
);

const getDriverForId = (drivers, id) => {
  const index = drivers.map((o) => o.id).indexOf(id);
  return drivers[index];
};

class DriverDetailsContainer extends Component {
  constructor(props) {
    super(props);

    var driver;
    if (props.drivers && props.drivers.length > 0) {
      driver = getDriverForId(props.drivers, props.match.params.id);
    } else {
      props.requestDriverList(props.token);
      driver = null;
    }

    this.state = {
      driver: driver,
      isMessageModalOpen: false,
      messageContent: "",
      isMonthDropdownOpen: false,
      selectedMonth: null,
      isYearDropdownOpen: false,
      selectedYear: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.drivers && nextProps.drivers.length > 0) {
      const driver = getDriverForId(
        nextProps.drivers,
        nextProps.match.params.id
      );
      this.setState({ driver: driver });
    }
  }
  render() {
    return (
      <div>
        {this.state.driver ? (
          <div>
            {detailContentForDriver(this.state.driver, this.toggleMessageModal)}
            <br />
            <DriverOrderStatistics
              driverId={this.props.match.params.id}
              token={this.props.token}
            />
            <br />
            <DriverRatingsList
              driverId={this.props.match.params.id}
              token={this.props.token}
            />{" "}
          </div>
        ) : (
          <h2>Laden...</h2>
        )}
        <Modal
          isOpen={this.state.isMessageModalOpen}
          toggle={this.toggleMessageModal}
        >
          <ModalHeader>
            Nachricht an {(this.state.driver || { name: "" }).name}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="message">Nachricht</Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder={`Hallo ${
                    (this.state.driver || { name: "" }).name
                  }`}
                  value={this.state.messageContent}
                  onChange={(e) =>
                    this.setState({ messageContent: e.target.value })
                  }
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              color="primary"
              onClick={this.handleSendMessageClicked}
            >
              Absenden
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.props.isLoading}
          toggle={() => {
            console.log("CLOSE");
          }}
        >
          <ModalHeader>Laden...</ModalHeader>
        </Modal>
        <Modal
          isOpen={this.props.isSendMessageLoading}
          toggle={() => {
            console.log("CLOSE");
          }}
        >
          <ModalHeader>Senden...</ModalHeader>
        </Modal>
      </div>
    );
  }
  toggleMessageModal = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen });
  };
  toggleYearDropdown = () => {
    this.setState({ isYearDropdownOpen: !this.state.isYearDropdownOpen });
  };
  toggleMonthDropdown = () => {
    this.setState({ isMonthDropdownOpen: !this.state.isMonthDropdownOpen });
  };
  handleYearChange = (year) => {
    this.setState({ selectedYear: year });
  };
  handleMonthChange = (month) => {
    this.setState({ selectedMonth: month });
  };
  handleSendMessageClicked = () => {
    this.props.requestSendDriverMessage(
      this.props.token,
      this.state.driver.id,
      this.state.messageContent
    );
    this.setState({ isMessageModalOpen: false, messageContent: "" });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.authentication.token,
    drivers: state.drivers.driverList,
    isLoading: state.drivers.driverListLoading,
    isSendMessageLoading: state.drivers.sendMessageLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestDriverList,
      requestSendDriverMessage,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DriverDetailsContainer)
);
