import { call, put, takeLatest } from "redux-saga/effects";
import { addressCompletion } from "../lib/api";
import {
  ADDRESS_COMPLETION_REQUESTED,
  successfullAddressCompletion,
  failedAddressCompletion,
} from "../actions/address";

function* addressCompletionWorker(action) {
  try {
    const options = yield call(addressCompletion, action.payload.query);
    yield put(successfullAddressCompletion(options));
  } catch (e) {
    yield put(failedAddressCompletion(e.message));
  }
}

export function* addressCompletionSaga() {
  yield takeLatest(ADDRESS_COMPLETION_REQUESTED, addressCompletionWorker);
}
