import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import "moment/locale/de";
import { Parser } from "json2csv";
import {
  Table,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Alert,
  Spinner,
} from "reactstrap";
import { createdInYearAndMonth, notPayedInCash } from "../../lib/orderFilters";
import { Order, OrderStatusType } from "../../lib/Order";
import { unpageApi } from "../../lib/unpage";
import { cities } from "../../cities";
import { capitalize } from "lodash";

moment.locale("de");

const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const triggerCSVDownload = (content: any, month: string) => {
  const file = new Blob([content], { type: "text/plain" });
  const element = document.createElement("a");
  element.href = URL.createObjectURL(file);
  element.download = `Orders_${month}.csv`;
  element.click();
};

const insertAt = (into: string, index: number, string: string) => {
  return into.substr(0, index) + string + into.substr(index);
};

export const AccountingContainer: React.FC<{}> = () => {
  const token = useSelector((state: any) => {
    return state.authentication.token;
  });

  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<string>();
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState<string>();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAllOrdersUnpaged = async () => {
      setIsLoading(true);
      try {
        const orders = await unpageApi<Order>(token, "admins/orders");
        setOrders(orders);
      } catch (error) {
        alert("Fehler: " + JSON.stringify(error));
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllOrdersUnpaged();
  }, [token]);

  const toggleYearDropdown = () => {
    setIsYearDropdownOpen((v) => !v);
  };
  const toggleMonthDropdown = () => {
    setIsMonthDropdownOpen((v) => !v);
  };
  const toggleCityDropdown = () => {
    setIsCityDropdownOpen((v) => !v);
  };
  const exportData = () => {
    const data = orders
      .filter(createdInYearAndMonth(selectedYear, selectedMonth))
      .filter((o) => o.state_type === OrderStatusType.Finished)
      .filter(notPayedInCash)
      .filter((o) => (selectedCity ? o.city === selectedCity : true))
      .map((o) => {
        const netto = "" + o.price.exclTax;
        const brutto = "" + o.price.customer;
        const tax = "" + (o.price.customer - o.price.exclTax);
        return {
          netto: insertAt(netto, netto.length - 2, ","),
          brutto: insertAt(brutto, brutto.length - 2, ","),
          tax: insertAt(tax, tax.length - 2, ","),
          name: `${o.first_name} ${o.last_name}`,
          booking_date: moment(o.createdAt)
            .tz("Europe/Berlin")
            .format("DD.MM.YYYY"),
          gegenkonto: "8400",
          orderId: o.order_number,
          debitorennummer: `${o.isPro ? "10001" : "10000"}`,
        };
      });
    const opts = { quote: "", delimiter: ";" };
    const parser = new Parser(opts);
    const csv = parser.parse(data);
    triggerCSVDownload(csv, selectedMonth || "ALL");
  };

  const allOrdersInMonthAndCity = orders
    .filter(createdInYearAndMonth(selectedYear, selectedMonth))
    .filter((o) => (selectedCity ? o.city === selectedCity : true));

  const finishedOrdersInMonthAndCity = allOrdersInMonthAndCity.filter(
    (o) => o.state_type === OrderStatusType.Finished
  );

  const finishedAndPaidOrdersInMonthAndCity =
    finishedOrdersInMonthAndCity.filter(notPayedInCash);

  return (
    <div className="accounting-container">
      <Row className="justify-content-between">
        <Col xs="auto">
          <h2>Abrechnung</h2>
        </Col>
        <Col xs="auto">
          <ButtonDropdown
            direction="left"
            isOpen={isYearDropdownOpen}
            toggle={toggleYearDropdown}
          >
            <DropdownToggle caret>{selectedYear || "Jahr"}</DropdownToggle>
            <DropdownMenu>
              {[
                "2018",
                "2019",
                "2020",
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
              ].map((y) => (
                <DropdownItem key={y} onClick={() => setSelectedYear(y)}>
                  {y}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          {/* MONTH */}
          <ButtonDropdown
            direction="left"
            isOpen={isMonthDropdownOpen}
            toggle={toggleMonthDropdown}
            style={{ marginLeft: "10px" }}
          >
            <DropdownToggle caret>{selectedMonth || "Monat"}</DropdownToggle>
            <DropdownMenu>
              {moment.months().map((m) => (
                <DropdownItem key={m} onClick={() => setSelectedMonth(m)}>
                  {m}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          {/* CITY */}
          <ButtonDropdown
            direction="left"
            isOpen={isCityDropdownOpen}
            toggle={toggleCityDropdown}
            style={{ marginLeft: "10px" }}
          >
            <DropdownToggle caret>
              {selectedCity ? capitalize(selectedCity) : "Stadt"}
            </DropdownToggle>
            <DropdownMenu>
              {cities.map((c) => (
                <DropdownItem key={c} onClick={() => setSelectedCity(c)}>
                  {capitalize(c)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          <Button onClick={exportData} style={{ marginLeft: "10px" }}>
            Exportieren
          </Button>
        </Col>
      </Row>
      {!isLoading && (
        <Row>
          <Col xs="12" lg="6">
            <Table borderless size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Fahrten</th>
                  <th>Umsatz (Netto)</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && (
                  <>
                    <tr>
                      <td>Gesamt</td>
                      <td>{allOrdersInMonthAndCity.length}</td>
                      <td>
                        {priceFormatter.format(
                          allOrdersInMonthAndCity.reduce(
                            (accumulator, order) =>
                              accumulator + order.price.exclTax,
                            0
                          ) / 100
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Abgeschlossen</td>
                      <td>{finishedOrdersInMonthAndCity.length}</td>
                      <td>
                        {priceFormatter.format(
                          finishedOrdersInMonthAndCity.reduce(
                            (accumulator, order) =>
                              accumulator + order.price.exclTax,
                            0
                          ) / 100
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Online bezahlt</td>
                      <td>{finishedAndPaidOrdersInMonthAndCity.length}</td>
                      <td>
                        {priceFormatter.format(
                          finishedAndPaidOrdersInMonthAndCity.reduce(
                            (accumulator, order) =>
                              accumulator + order.price.exclTax,
                            0
                          ) / 100
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
            <Alert color="warning">
              Die Statistik enthält ALLE Aufträge im gewählten Zeitraum
              inklusive Family&Friends.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        {isLoading && (
          <Col>
            <Spinner />
          </Col>
        )}
        {!isLoading && (
          <Table hover responsive>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Auftragsnummer</th>
                <th>Kunde</th>
                <th>Onlinezahlung</th>
              </tr>
            </thead>
            <tbody>
              {finishedAndPaidOrdersInMonthAndCity.map((order) => (
                <tr key={order.id}>
                  <td>
                    {moment(order.createdAt)
                      .tz("Europe/Berlin")
                      .format("DD.MM.YYYY")}
                  </td>
                  <td>{order.order_number}</td>
                  <td>{`${order.first_name} ${order.last_name}`}</td>
                  <td>
                    <div style={{ textAlign: "center" }}>
                      {notPayedInCash(order) ? "✅" : "❌"}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Row>
    </div>
  );
};
