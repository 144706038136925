import { loginSaga, accountInfoSaga } from "./authentication";
import {
  confirmOrderSaga,
  editOrderSaga,
  resendOfferDriversSaga,
} from "./orders";
import { driverListSaga, driverSendMessageSaga } from "./drivers";
import { addressCompletionSaga } from "./address";

export const sagas = [
  loginSaga,
  accountInfoSaga,
  confirmOrderSaga,
  editOrderSaga,
  resendOfferDriversSaga,
  driverListSaga,
  driverSendMessageSaga,
  addressCompletionSaga,
];

export default sagas;
