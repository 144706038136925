import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input
  } from "reactstrap";
import { allMoveTypes, readableMoveType } from "../../lib/formatters";

export default class AddressDetailsPicker extends Component {
    render() {
        return (
            <Row>
            <Col xs="12" sm="6">
              <Form>
                <FormGroup>
                  <Label>Typ</Label>
                  <Input type="select" value={this.props.value.move_type} onChange={event => this.props.handleChange({move_type: event.target.value})}>
                    {allMoveTypes.map(t => (
                      <option value={t}>{readableMoveType(t)}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Stockwerk</Label>
                  <Input type="number" value={this.props.value.story} onChange={event => this.props.handleChange({story: parseInt(event.target.value, 10)})}/>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input type="checkbox" checked={this.props.value.elevator} onChange={event => this.props.handleChange({elevator: event.target.checked})}/> Fahrstuhl
                  </Label>
                </FormGroup>
              </Form>
              <br />
            </Col>
            <Col xs="12" sm="6">
              <Form>
                <FormGroup check inline>
                  <Label check>
                    <Input type="checkbox" checked={this.props.value.hasContact} onChange={event => this.props.handleChange({hasContact: event.target.checked})}/> Alternativer Kontakt
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type="text" value={this.props.value.contactName} onChange={event => this.props.handleChange({contactName: event.target.value})} />
                </FormGroup>
                <FormGroup>
                  <Label>Telefonnummer</Label>
                  <Input type="text" value={this.props.value.contactPhone} onChange={event => this.props.handleChange({contactPhone: event.target.value})}/>
                </FormGroup>
              </Form>
              <br />
            </Col>
          </Row>
        )
    }
}