export enum Stage {
  Development = "dev",
  Staging = "staging",
  Production = "production",
}

const stage = process.env.REACT_APP_CUSTOM_STAGE as Stage;

const APIUrls: Record<Stage, string> = {
  dev: "https://dev-api.movemates.de",
  staging: "https://staging-api.movemates.de",
  production: "https://api.movemates.de",
};

export const APIUrl = APIUrls[stage];

const braintreeUrls: Record<Stage, string> = {
  dev: "https://sandbox.braintreegateway.com/merchants/zb8j34244y2rfj5n",
  staging: "https://sandbox.braintreegateway.com/merchants/zb8j34244y2rfj5n",
  production: "https://braintreegateway.com/merchants/zk8j396y4y9ft3jd",
};

export const braintreeUrl = braintreeUrls[stage];

export const hereApiKey = "i8gIFPK9EdaHdxJ_6kyy1MH3Q0hMzb6CkAmM4EQ84ro";
