import { call, put, takeLatest } from "redux-saga/effects";
import { orderAction, editOrder } from "../lib/api";
import {
  CONFIRM_ORDER_REQUESTED,
  RESEND_OFFER_DRIVERS_REQUESTED,
  EDIT_ORDER_REQUESTED,
  successfullConfirmOrder,
  failedConfirmOrder,
  successfullResendDriversOffer,
  failedResendDriversOffer,
  successfullEditOrder,
  failedEditOrder,
} from "../actions/orders";

function* confirmOrderWorker(action) {
  try {
    const response = yield call(
      orderAction,
      action.payload.token,
      action.payload.orderId,
      "force-confirm"
    );
    yield put(successfullConfirmOrder(response));
  } catch (e) {
    yield put(failedConfirmOrder(e.message));
  }
}

export function* confirmOrderSaga() {
  yield takeLatest(CONFIRM_ORDER_REQUESTED, confirmOrderWorker);
}

function* resendOfferDriversWorker(action) {
  try {
    const response = yield call(
      orderAction,
      action.payload.token,
      action.payload.orderId,
      "resend-drivers"
    );
    yield put(successfullResendDriversOffer(response));
  } catch (e) {
    yield put(failedResendDriversOffer(e.message));
  }
}

export function* resendOfferDriversSaga() {
  yield takeLatest(RESEND_OFFER_DRIVERS_REQUESTED, resendOfferDriversWorker);
}

function* editOrderWorker(action) {
  try {
    const response = yield call(
      editOrder,
      action.payload.token,
      action.payload.updateValues
    );
    yield put(successfullEditOrder(response));
  } catch (e) {
    yield put(failedEditOrder(e));
  }
}

export function* editOrderSaga() {
  yield takeLatest(EDIT_ORDER_REQUESTED, editOrderWorker);
}
