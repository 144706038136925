export const DRIVER_LIST_REQUESTED = 'DRIVER_LIST_REQUESTED'
export const DRIVER_LIST_SUCCEEDED = 'DRIVER_LIST_SUCCEEDED'
export const DRIVER_LIST_FAILED = 'DRIVER_LIST_FAILED'

export function requestDriverList(token) {
    return {
        type: DRIVER_LIST_REQUESTED,
        payload: token
    }
}

export function successfullDriverList(driverList) {
    return {
        type: DRIVER_LIST_SUCCEEDED,
        payload: driverList
    }
}

export function failedDriverList(message) {
    return {
        type: DRIVER_LIST_FAILED,
        payload: message
    }
}

export const DRIVER_SEND_MESSAGE_REQUESTED = 'DRIVER_SEND_MESSAGE_REQUESTED'
export const DRIVER_SEND_MESSAGE_SUCCEEDED = 'DRIVER_SEND_MESSAGE_SUCCEEDED'
export const DRIVER_SEND_MESSAGE_FAILED = 'DRIVER_SEND_MESSAGE_FAILED'

export function requestSendDriverMessage(token, driverId, message) {
    return {
        type: DRIVER_SEND_MESSAGE_REQUESTED,
        payload: {
            token,
            driverId,
            message
        }
    }
}

export function successfullSendDriverMessage() {
    return {
        type: DRIVER_SEND_MESSAGE_SUCCEEDED,
    }
}

export function failedSendDriverMessage(message) {
    return {
        type: DRIVER_SEND_MESSAGE_FAILED,
        payload: message
    }
}