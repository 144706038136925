import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { requestUserLogin } from "../../actions/authentication";
import logo from "../../assets/logo.svg";
import "./LoginContainer.scss";

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }
  render() {
    return (
      <div className="login-container">
        <Form className="form-signin">
          <img
            className="mb-4 app-logo"
            src={logo}
            alt=""
            width="100"
            height="100"
          />
          <h1 className="h3 mb-3 font-weight-normal">
            Movemates Customer Service
          </h1>
          <FormGroup>
            <Label className="sr-only" for="inputEmail">
              Email-Adresse
            </Label>
            <Input
              type="email"
              id="inputEmail"
              className="form-control"
              placeholder="Email-Adresse"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              required
            />
            <Label className="sr-only" for="inputPassword">
              Passwort
            </Label>
            <Input
              type="password"
              id="inputPassword"
              placeholder="Passwort"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              required
            />
            <Button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.requestUserLogin(
                  this.state.email,
                  this.state.password
                );
              }}
            >
              Anmelden
            </Button>
          </FormGroup>
          <p className="mt-5 mb-3 text-muted">&copy; Movemates UG 2017-2020</p>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestUserLogin,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
