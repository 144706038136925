// State Definitions
const state_unconfirmed = "unconfirmed";
const state_awaiting_charge = "charge.pending";
const state_payment_failed = "charge.failed";
const state_payment_cancelled = "charge.cancelled";
const state_pending = "pending";
const state_accepted = "driver.accepted";
const state_in_progress = "driver.in_progress";
const state_finished = "driver.finished";
const state_cancelled = "cancelled";
const state_expired = "expired";
const state_refunded_customer = "customer.refunded";

export const valid_types = [
  state_unconfirmed,
  state_awaiting_charge,
  state_payment_failed,
  state_payment_cancelled,
  state_pending,
  state_accepted,
  state_in_progress,
  state_finished,
  state_cancelled,
  state_expired,
  state_refunded_customer,
];

export const finished_types = [state_finished];

export const confirmed_forthcoming_types = [
  state_accepted,
  state_pending,
  state_in_progress,
];

const force_confirmable_states = [
  state_unconfirmed,
  state_awaiting_charge,
  state_payment_failed,
  state_payment_cancelled,
];

const cancelable_states = [
  state_accepted,
  state_unconfirmed,
  state_pending,
  state_in_progress,
];

export const isForceConfirmableState = (state) => {
  return force_confirmable_states.indexOf(state) !== -1;
};

export const isCancelableState = (state) => {
  return cancelable_states.indexOf(state) !== -1;
};
