import { Component } from "react";
import { Row, Col } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

class AddressPicker extends Component {
  render() {
    return (
      <Row>
        <Col xs="12">
          <AsyncTypeahead
            multiple={false}
            minLength={3}
            options={this.props.options}
            onSearch={this.props.handleSearch}
            isLoading={this.props.isLoading}
            placeholder={this.props.initialValue}
            labelKey="title"
            filterBy={(item) => item.resultType === "houseNumber"}
            onChange={(selected) => {
              this.props.onChange(selected[0]);
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default AddressPicker;
