import {
  CONFIRM_ORDER_REQUESTED,
  CONFIRM_ORDER_SUCCEEDED,
  CONFIRM_ORDER_FAILED,
  EDIT_ORDER_REQUESTED,
  EDIT_ORDER_SUCCEEDED,
  EDIT_ORDER_FAILED,
  RESEND_OFFER_DRIVERS_REQUESTED,
  RESEND_OFFER_DRIVERS_SUCCEEDED,
  RESEND_OFFER_DRIVERS_FAILED,
} from "../actions/orders";

export default function orders(
  state = {
    confirmOrderLoading: false,
    resendOfferDriversLoading: false,
    editOrderLoading: false,
    editOrderError: undefined,
  },
  action
) {
  switch (action.type) {
    case CONFIRM_ORDER_REQUESTED:
      return Object.assign({}, state, {
        confirmOrderLoading: true,
      });
    case CONFIRM_ORDER_SUCCEEDED:
      return Object.assign({}, state, {
        confirmOrderLoading: false,
      });
    case CONFIRM_ORDER_FAILED:
      return Object.assign({}, state, {
        confirmOrderLoading: false,
      });
    case EDIT_ORDER_REQUESTED:
      return Object.assign({}, state, {
        editOrderLoading: true,
        editOrderError: undefined,
      });
    case EDIT_ORDER_SUCCEEDED:
      return Object.assign({}, state, {
        editOrderLoading: false,
        editOrderError: undefined,
      });
    case EDIT_ORDER_FAILED:
      return Object.assign({}, state, {
        editOrderLoading: false,
        editOrderError: action.payload,
      });
    case RESEND_OFFER_DRIVERS_REQUESTED:
      return Object.assign({}, state, {
        resendOfferDriversLoading: true,
      });
    case RESEND_OFFER_DRIVERS_SUCCEEDED:
      return Object.assign({}, state, {
        resendOfferDriversLoading: false,
      });
    case RESEND_OFFER_DRIVERS_FAILED:
      return Object.assign({}, state, {
        resendOfferDriversLoading: false,
      });
    default:
      return state;
  }
}
