import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import {
  Modal,
  ModalHeader,
  Button,
  ButtonGroup,
  Row,
  Col,
  PopoverHeader,
  PopoverBody,
  Popover,
} from "reactstrap";
import { readableStory, readableMoveType } from "../../lib/formatters";
import {
  isCancelableState,
  isForceConfirmableState,
} from "../../lib/orderStates";
import { confirmOrder, resendDriversOffer } from "../../actions/orders";
import "./OrderDetailsContainer.scss";
import { fetchOrder, orderAction } from "../../lib/api";
import { Address, Order } from "../../lib/Order";
import { braintreeUrl } from "../../lib/constants";
import { AdminOrderAction } from "../../lib/AdminOrderAction";

const LocationSummaryFromLocation = (
  location: Address,
  fallbackContactName: string,
  fallbackContactPhone: string,
  handlingTime: number
) => (
  <Row>
    <Col xs="12" sm="6">
      <span>Adresse</span>
      <h5>
        {`${location.street} ${location.houseNumber}`}
        <br />
        {`${location.postalCode} ${location.city}`}
      </h5>
      <span>Stockwerk</span>
      <h5>{readableStory(location.story)}</h5>
      <span>Art der Hilfe</span>
      <h5>{readableMoveType(location.move_type)}</h5>
    </Col>
    <Col xs="12" sm="6">
      <span>Kontakt</span>
      {location.contact ? (
        <h5>
          {location.contact.name}
          <br />
          <a href={`tel:${location.contact.phone}`}>{location.contact.phone}</a>
        </h5>
      ) : (
        <h5>
          {fallbackContactName}
          <br />
          <a href={`tel:${fallbackContactPhone}`}>{fallbackContactPhone}</a>
        </h5>
      )}
      <span>Geschätzte Ladezeit</span>
      <h5>{Math.round(handlingTime / 60)} Minuten</h5>
    </Col>
  </Row>
);

interface DetailContentForOrderProps {
  order: Order;
  editHandler: (order: Order) => void;
  confirmHandler: (order: Order) => void;
  resendHandler: (order: Order) => void;
  cancelHandler: (order: Order) => void;
}

const DetailContentForOrder: React.FC<DetailContentForOrderProps> = (props) => {
  const { order, editHandler, resendHandler, confirmHandler, cancelHandler } =
    props;

  const [isCancelPopoverOpen, setIsCancelPopoverOpen] = useState(false);

  return (
    <div>
      <Row className="justify-content-between">
        <Col xs="auto">
          <h2>Details für Auftrag {order.order_number}</h2>
        </Col>
        <Col xs="auto">
          <ButtonGroup>
            <Button outline color="primary" onClick={() => editHandler(order)}>
              Bearbeiten
            </Button>
            <Button
              outline
              color="primary"
              onClick={() => confirmHandler(order)}
              disabled={!isForceConfirmableState(order?.state_type)}
            >
              Bestätigung erzwingen
            </Button>
            <Button
              outline
              color="primary"
              onClick={() => resendHandler(order)}
            >
              Erneut an Fahrer senden
            </Button>
            <Button
              outline
              color="danger"
              disabled={!isCancelableState(order?.state_type)}
              id="cancel-order-button"
            >
              Stornieren
            </Button>
            <Popover
              target="cancel-order-button"
              isOpen={isCancelPopoverOpen}
              toggle={() => setIsCancelPopoverOpen((v) => !v)}
            >
              <PopoverHeader>Auftrag stornieren</PopoverHeader>
              <PopoverBody>
                Möchtest du diesen Auftrag wirklich stornieren? Der Kunde wird
                über die Stornierung informiert und der gezahlte Betrag wird
                erstattet.
                <br />
                <br />
                <ButtonGroup>
                  <Button
                    color="danger"
                    onClick={() => {
                      setIsCancelPopoverOpen((v) => !v);
                      cancelHandler(order);
                    }}
                    disabled={!isCancelableState(order?.state_type)}
                  >
                    Auftrag stornieren
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setIsCancelPopoverOpen((v) => !v)}
                  >
                    Abbrechen
                  </Button>
                </ButtonGroup>
              </PopoverBody>
            </Popover>
          </ButtonGroup>
        </Col>
        <Col xs="12">
          <div className="separator" />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <span>Kunde</span>
          <h5>{`${order.first_name} ${order.last_name}`}</h5>
          <span>Telefon</span>
          <h5>
            <a href={`tel:${order.phone}`}>{order.phone}</a>
          </h5>
          <span>E-Mail</span>
          <h5>
            <a href={`mailto:${order.email}`}>{order.email}</a>
          </h5>
          <span>Art</span>
          <h5>{order.isPro ? "Geschäftskunde" : "Privatkunde"}</h5>
          <span>Notizen</span>
          <h5>{order.notes}</h5>
          {order.driver_notes && (
            <div>
              <span>Notizen für Fahrer</span>
              <h5>{order.driver_notes}</h5>
            </div>
          )}
          <h5>
            <a
              href={`https://www.google.de/maps/dir/${order.from.label}/@${order.from.location.lat},${order.from.location.lon}/${order.to.label}/@${order.to.location.lat},${order.to.location.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Route in Google Maps öfnen
            </a>
          </h5>
          <span>Rechnungsadresse</span>
          <h5>
            {order.invoiceRequest ? (
              <span>
                {order.invoiceRequest.companyName && (
                  <>
                    {order.invoiceRequest.companyName}
                    <br />
                  </>
                )}
                {order.invoiceRequest.name}
                <br />
                {order.invoiceRequest.street} {order.invoiceRequest.houseNumber}
                <br />
                {order.invoiceRequest.zip} {order.invoiceRequest.city}
              </span>
            ) : (
              "keine Rechnung angefordert"
            )}
          </h5>
          {order.invoiceRequest && (
            <>
              <span>Automatischer Rechnungsversand</span>
              <h5>
                {order.invoiceRequest.shouldBeEmailedAutomatically
                  ? `Aktiviert (${order.email})`
                  : "Deaktiviert"}
              </h5>
            </>
          )}
          {order.invoiceRequest && (
            <h5>
              {order.invoiceUrl ? (
                <a
                  href={order.invoiceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rechnung herunterladen
                </a>
              ) : (
                "Rechnung wird bei Auftragsabschluss generiert"
              )}
            </h5>
          )}
        </Col>
        <Col xs="12" sm="6">
          <span>Zeitpunkt</span>
          <h5>
            {moment(order.date).tz("Europe/Berlin").format("DD.MM.YYYY HH:mm")}
            Uhr
          </h5>
          <span>Status</span>
          <h5>{order.state_type}</h5>
          <span>Kundenpreis</span>
          <h5>
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(order.price.customer / 100)}
          </h5>
          <span>Nettopreis</span>
          <h5>
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(order.price.exclTax / 100)}
          </h5>
          <span>Fahrerpreis</span>
          <h5>
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(order.price.driver / 100)}
          </h5>
          <div>
            <span>Zahlung</span>
            <h5>
              {!order.charge_id && !order.transaction_id && "unbezahlt"}
              {order.charge_id && (
                <a
                  href={`https://dashboard.stripe.com/payments/${order.charge_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`Stripe: ${order.charge_id}`}
                </a>
              )}
              {order.transaction_id && (
                <>
                  <a
                    href={`${braintreeUrl}/transactions/${order.transaction_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${order.transaction_id} (${order.paymentMethod})`}
                  </a>
                </>
              )}
            </h5>
          </div>

          <span>Buchungszeitpunkt</span>
          <h5>
            {moment(order.createdAt)
              .tz("Europe/Berlin")
              .format("DD.MM.YYYY HH:mm")}
            Uhr
          </h5>
          <span>Geschätzte Fahrzeit</span>
          <h5>{Math.round(order.estimation.ride_time / 60)} Minuten</h5>
          <span>Geschätzte Ladezeit (gesamt)</span>
          <h5>
            {Math.round(
              (order.estimation.handling_time_from +
                order.estimation.handling_time_to) /
                60
            )}{" "}
            Minuten
          </h5>
        </Col>
      </Row>
      <div className="spacer" />
      <Row>
        <Col xs="12">
          <h2>Abholung</h2>
          <div className="separator" />
        </Col>
      </Row>
      {LocationSummaryFromLocation(
        order.from,
        `${order.first_name} ${order.last_name}`,
        order.phone,
        order.estimation.handling_time_from
      )}
      <div className="spacer" />
      <Row>
        <Col xs="12">
          <h2>Lieferung</h2>
          <div className="separator" />
        </Col>
      </Row>
      {LocationSummaryFromLocation(
        order.to,
        `${order.first_name} ${order.last_name}`,
        order.phone,
        order.estimation.handling_time_to
      )}
      <div className="spacer" />
      <Row>
        <Col xs="12">
          <h2>Gegenstände</h2>
          <div className="separator" />
        </Col>
      </Row>
      <Row>
        <Col>
          {(order.items || []).map((i, index) => (
            <h5 key={index}>
              {i.quantity}x {i.name}
            </h5>
          ))}
          {(order.custom_items || []).map((i, index) => (
            <h5 key={index}>
              {i.quantity}x {i.notes} {i.height}x{i.width}x{i.depth}cm,{" "}
              {i.weight}kg
            </h5>
          ))}
        </Col>
      </Row>
      <div className="spacer" />
    </div>
  );
};

export const OrderDetailsContainer: React.FC<{}> = () => {
  const { token, isLoadingResend, isLoadingConfirm } = useSelector(
    (state: any) => {
      return {
        token: state.authentication.token,
        isLoadingResend: state.orders.resendOfferDriversLoading as boolean,
        isLoadingConfirm: state.orders.confirmOrderLoading as boolean,
      };
    }
  );

  const { id: orderId } = useParams<{ id: string }>();

  const [order, setOrder] = useState<Order>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadOrder = async () => {
      setIsLoading(true);
      try {
        const order = await fetchOrder(token, orderId);
        setOrder(order);
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadOrder();
  }, [token, orderId]);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleEditClicked = (order: Order) => {
    history.push(`/orders/${order.id}/edit`);
  };
  const handleConfirmClicked = (order: Order) => {
    dispatch(confirmOrder(token, order.id));
  };
  const handleResendDriversOfferClicked = (order: Order) => {
    dispatch(resendDriversOffer(token, order.id));
  };
  const handleCancelClicked = async (order: Order) => {
    setIsLoading(true);
    try {
      const canceledOrder = await orderAction(
        token,
        order.id,
        AdminOrderAction.Cancel
      );
      setOrder(canceledOrder);
    } catch (error) {
      alert((error as any).error || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {order ? (
        <DetailContentForOrder
          order={order}
          editHandler={handleEditClicked}
          confirmHandler={handleConfirmClicked}
          resendHandler={handleResendDriversOfferClicked}
          cancelHandler={handleCancelClicked}
        />
      ) : (
        <h2>Laden...</h2>
      )}
      <Modal
        isOpen={isLoading || isLoadingConfirm || isLoadingResend}
        toggle={() => {
          console.log("CLOSE");
        }}
      >
        <ModalHeader>Laden...</ModalHeader>
      </Modal>
    </div>
  );
};
