import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import LoginContainer from './components/LoginContainer/LoginContainer'
import DashboardContainer from './components/DashboardContainer/DashboardContainer'

class App extends Component {
  render() {
    return (
      <div className="App">
      {this.props.isLoggedIn ? (
        <DashboardContainer />
      ) : (
        <LoginContainer />
      )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authentication.token && state.authentication.token.length > 0
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
