export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED'
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED'
export const ACCOUNT_INFO_REQUESTED = 'ACCOUNT_INFO_REQUESTED'
export const ACCOUNT_INFO_SUCCEEDED = 'ACCOUNT_INFO_SUCCEEDED'
export const ACCOUNT_INFO_FAILED = 'ACCOUNT_INFO_FAILED'

export function requestUserLogin(email, password) {
    return {
        type: USER_LOGIN_REQUESTED,
        payload: {
            email,
            password
        }
    }
}

export function successfullUserLogin(token) {
    return {
        type: USER_LOGIN_SUCCEEDED,
        payload: token
    }
}

export function failedUserLogin(message) {
    return {
        type: USER_LOGIN_FAILED,
        payload: message
    }
}

export function requestUserLogout() {
    return {
        type: USER_LOGOUT_REQUESTED
    }
}

export function requestAccountInfo(token) {
    return {
        type: ACCOUNT_INFO_REQUESTED,
        payload: token
    }
}

export function successfullAccountInfo(accountInfo) {
    return {
        type: ACCOUNT_INFO_SUCCEEDED,
        payload: accountInfo
    }
}

export function failedAccountInfo(message) {
    return {
        type: ACCOUNT_INFO_FAILED,
        payload: message
    }
}