import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import * as Icon from "react-feather";
import { requestUserLogout } from "../../actions/authentication";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import HomeContainer from "../HomeContainer/HomeContainer";
import { OrdersListContainer } from "../OrdersListContainer/OrdersListContainer";
import { OrderDetailsContainer } from "../OrderDetailsContainer/OrderDetailsContainer";
import { EditOrderContainer } from "../EditOrderContainer/EditOrderContainer";
import DriverListContainer from "../DriverListContainer/DriverListContainer";
import DriverDetailsContainer from "../DriverDetailsContainer/DriverDetailsContainer";
import { AccountingContainer } from "../AccountingContainer/AccountingContainer";
import SettingsContainer from "../SettingsContainer/SettingsContainer";
import "./DashboardContainer.scss";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <HomeContainer />,
  },
  {
    path: "/orders",
    exact: true,
    component: () => <OrdersListContainer />,
  },
  {
    path: "/orders/:id",
    exact: true,
    component: () => <OrderDetailsContainer />,
  },
  {
    path: "/orders/:id/edit",
    exact: true,
    component: () => <EditOrderContainer />,
  },
  {
    path: "/drivers",
    exact: true,
    component: () => <DriverListContainer />,
  },
  {
    path: "/drivers/:id",
    exact: true,
    component: () => <DriverDetailsContainer />,
  },
  {
    path: "/accounting",
    exact: true,
    component: () => <AccountingContainer />,
  },
  {
    path: "/settings",
    exact: true,
    component: () => <SettingsContainer />,
  },
];

class DashboardContainer extends Component {
  render() {
    return (
      <Router>
        <div>
          <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
            <span className="navbar-brand col-sm-3 col-md-2 mr-0">
              Movemates
            </span>
            <ul className="navbar-nav px-3">
              <li className="nav-item text-nowrap">
                <Button
                  color="link"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.requestUserLogout();
                  }}
                >
                  Abmelden
                </Button>
              </li>
            </ul>
          </nav>

          <div className="container-fluid">
            <div className="row">
              <nav className="col-sm-3 col-md-2 bg-light sidebar">
                <div className="sidebar-sticky">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <NavLink
                        to="/"
                        className="nav-link"
                        activeClassName="active"
                        exact={true}
                      >
                        <Icon.Home size={16} />
                        Startseite
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/orders"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <Icon.File size={16} />
                        Aufträge
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/drivers"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <Icon.Truck size={16} />
                        Fahrer
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/accounting"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <Icon.Layers size={16} />
                        Abrechnung
                      </NavLink>
                    </li>
                  </ul>

                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Account</span>
                  </h6>
                  <ul className="nav flex-column mb-2">
                    <li className="nav-item">
                      <NavLink
                        to="/settings"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <Icon.Settings size={16} />
                        Einstellungen
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>

              <main role="main" className="col-sm-9 col-md-10 pt-3 px-4">
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </main>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestUserLogout,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
