import { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "reactstrap";
import moment from "moment";
import { fetchRatings } from "../lib/api";
import { Rating } from "../lib/Rating";
import { Link } from "react-router-dom";
import { sumBy } from "lodash";

moment.locale("de");

export const DriverRatingsList = ({
  driverId,
  token,
}: {
  token: string;
  driverId: string;
}) => {
  const [ratings, setRatings] = useState<Rating[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadRatings = async () => {
      try {
        setIsLoading(true);
        const ratings = await fetchRatings(token, driverId);
        console.log(ratings);
        setRatings(ratings);
      } catch (error) {
        console.error(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadRatings();
  }, [driverId, token, setRatings]);

  return (
    <>
      <Row className="justify-content-between">
        <Col xs="auto">
          <h4 style={{ marginBottom: 0 }}>Bewertungen</h4>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col xs="auto">{ratings.length} Bewertungen</Col>
      </Row>
      {ratings.length > 0 && (
        <>
          <Row className="justify-content-between">
            <Col xs="auto">
              {(
                sumBy(ratings, (r) => r.points) / ratings.length
              ).toLocaleString("de", { maximumFractionDigits: 2 })}{" "}
              Punkte Durchschnitt
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="separator" />
            </Col>
            <Col xs="12">
              {isLoading && <Spinner />}
              <>
                <Table borderless size="sm">
                  <tbody>
                    {ratings.map((r) => (
                      <tr>
                        <td>
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (i <= r.points - 1 ? "★" : "☆"))
                            .join("")}
                        </td>
                        <td>{r.text ?? "–"}</td>
                        <td>
                          <Link to={`/orders/${r.order}`}>Auftrag</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
