import { call, put, takeLatest } from "redux-saga/effects";
import { fetchDrivers, sendNotificationToDriver } from "../lib/api";
import {
  DRIVER_LIST_REQUESTED,
  successfullDriverList,
  failedDriverList,
  DRIVER_SEND_MESSAGE_REQUESTED,
  successfullSendDriverMessage,
  failedSendDriverMessage,
} from "../actions/drivers";

function* driverListWorker(action) {
  try {
    const response = yield call(fetchDrivers, action.payload);
    yield put(successfullDriverList(response));
  } catch (e) {
    yield put(failedDriverList(e.message));
  }
}

export function* driverListSaga() {
  yield takeLatest(DRIVER_LIST_REQUESTED, driverListWorker);
}

function* driverSendMessageWorker(action) {
  try {
    const response = yield call(
      sendNotificationToDriver,
      action.payload.token,
      action.payload.driverId,
      action.payload.message
    );
    yield put(successfullSendDriverMessage(response));
  } catch (e) {
    yield put(failedSendDriverMessage(e.message));
  }
}

export function* driverSendMessageSaga() {
  yield takeLatest(DRIVER_SEND_MESSAGE_REQUESTED, driverSendMessageWorker);
}
