import moment from "moment";
import { Order } from "./Order";

export const notPayedInCash = (order: Order): boolean => {
  return !!order.transaction_id || !!order.charge_id || order.isPro;
};

export const createdInYearAndMonth = (
  year: string | undefined,
  month: string | undefined
): ((order: Order) => boolean) => {
  return (order) => {
    if (!year || !month) return true;
    const parsedMonth = moment.localeData("de").monthsParse(month, "", true);
    const parsedYear = parseInt(year);
    const start = moment().year(parsedYear).month(parsedMonth).startOf("month");
    const end = moment().year(parsedYear).month(parsedMonth).endOf("month");
    return moment(order.date).isBetween(start, end);
  };
};
