import React from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { InvoiceRequest } from "../lib/Order";

interface Props {
  value: Partial<InvoiceRequest>;
  handleChange: (value: Partial<InvoiceRequest>) => void;
}

export const InvoiceRequestForm: React.FC<Props> = (props) => {
  return (
    <Form>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="company_name">Firma</Label>
            <Input
              type="text"
              id="company_name"
              value={props.value && props.value.companyName}
              onChange={(event) =>
                props.handleChange({
                  companyName:
                    event.target.value && event.target.value.length > 0
                      ? event.target.value
                      : undefined,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={props.value && props.value.name}
              onChange={(event) =>
                props.handleChange({
                  name:
                    event.target.value && event.target.value.length > 0
                      ? event.target.value
                      : undefined,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="street">Straße</Label>
            <Input
              type="text"
              id="street"
              value={props.value && props.value.street}
              onChange={(event) =>
                props.handleChange({
                  street:
                    event.target.value && event.target.value.length > 0
                      ? event.target.value
                      : undefined,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="house_number">Hausnummer</Label>
            <Input
              type="text"
              id="house_number"
              value={props.value && props.value.houseNumber}
              onChange={(event) =>
                props.handleChange({
                  houseNumber:
                    event.target.value && event.target.value.length > 0
                      ? event.target.value
                      : undefined,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="zip">Postleitzahl</Label>
            <Input
              type="text"
              id="zip"
              value={props.value && props.value.zip}
              onChange={(event) =>
                props.handleChange({
                  zip:
                    event.target.value && event.target.value.length > 0
                      ? event.target.value
                      : undefined,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="city">Stadt</Label>
            <Input
              type="text"
              id="city"
              value={props.value && props.value.city}
              onChange={(event) =>
                props.handleChange({
                  city:
                    event.target.value && event.target.value.length > 0
                      ? event.target.value
                      : undefined,
                })
              }
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup check>
            <Input
              type="checkbox"
              id="should_be_emailed_automatically"
              checked={props.value && props.value.shouldBeEmailedAutomatically}
              onChange={(event) =>
                props.handleChange({
                  shouldBeEmailedAutomatically: event.target.checked,
                })
              }
            />
            <Label for="should_be_emailed_automatically" check>
              Automatischer E-Mail-Versand an den Kunden
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
