import { combineReducers } from 'redux'

import authentication from './authentication'
import orders from './orders'
import drivers from './drivers'
import address from './address'

const reducers = {
    authentication,
    orders,
    drivers,
    address
}

const reducer = combineReducers(reducers)

export default reducer