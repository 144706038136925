import React, { useEffect, useState } from "react";
import {
  Alert,
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import moment from "moment";
import { notPayedInCash } from "../lib/orderFilters";
import { Order } from "../lib/Order";
import { unpageApi } from "../lib/unpage";

moment.locale("de");

const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

interface Props {
  token: string;
  driverId: string;
}

export const DriverOrderStatistics: React.FC<Props> = (props) => {
  const { driverId, token } = props;

  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<string>();
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!selectedYear || !selectedMonth) return;
      const start = moment()
        .year(parseInt(selectedYear))
        .month(selectedMonth)
        .startOf("month");
      const end = moment()
        .year(parseInt(selectedYear))
        .month(selectedMonth)
        .endOf("month");
      try {
        setIsLoading(true);
        const orders = await unpageApi<Order>(
          token,
          `admins/orders?driver=${driverId}&start=${start.toISOString()}&end=${end.toISOString()}`
        );
        setOrders(orders);
      } catch (error) {
        console.error(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders();
  }, [driverId, token, selectedYear, selectedMonth]);

  const finishedAndPaidOrders = orders.filter(notPayedInCash);

  return (
    <>
      <Row className="justify-content-between">
        <Col xs="auto">
          <h4 style={{ marginBottom: 0 }}>Monatsabrechnung</h4>
        </Col>
        <Col xs="auto">
          <ButtonDropdown
            direction="left"
            isOpen={isYearDropdownOpen}
            toggle={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
          >
            <DropdownToggle caret>{selectedYear || "Jahr"}</DropdownToggle>
            <DropdownMenu>
              {["2018", "2019", "2020", "2021", "2022"].map((y) => (
                <DropdownItem key={y} onClick={() => setSelectedYear(y)}>
                  {y}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          <ButtonDropdown
            direction="left"
            isOpen={isMonthDropdownOpen}
            toggle={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
            style={{ marginLeft: "10px" }}
          >
            <DropdownToggle caret>{selectedMonth || "Monat"}</DropdownToggle>
            <DropdownMenu>
              {moment.months().map((m) => (
                <DropdownItem key={m} onClick={() => setSelectedMonth(m)}>
                  {m}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className="separator" />
        </Col>
        <Col xs="12" lg="6">
          {isLoading && <Spinner />}
          {selectedYear && selectedMonth && !isLoading && (
            <>
              <Table borderless size="sm">
                <thead>
                  <tr>
                    <th></th>
                    <th>Fahrten</th>
                    <th>Umsatz (Netto)</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && (
                    <>
                      <tr>
                        <td>Gesamt (abgeschlossen)</td>
                        <td>{orders.length}</td>
                        <td>
                          {priceFormatter.format(
                            orders.reduce(
                              (accumulator, order) =>
                                accumulator + order.price.driver,
                              0
                            ) / 100
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Online bezahlt</td>
                        <td>{finishedAndPaidOrders.length}</td>
                        <td>
                          {priceFormatter.format(
                            finishedAndPaidOrders.reduce(
                              (accumulator, order) =>
                                accumulator + order.price.driver,
                              0
                            ) / 100
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <Alert color="warning">
                Achtung: Es werden nur abgeschlossene Fahrten angezeigt.
              </Alert>
              <Alert color="secondary">
                <span>Privatkunden = Online bezahlt</span>
                <br />
                <span>Geschäftskunden = Zahlung auf Rechnung</span>
                <br />
                <span>
                  Kostenlose Fahrten für Family&Friends werden nicht beachtet
                </span>
              </Alert>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
