export const ADDRESS_COMPLETION_REQUESTED = "ADDRESS_COMPLETION_REQUESTED";
export const ADDRESS_COMPLETION_SUCCEEDED = "ADDRESS_COMPLETION_SUCCEEDED";
export const ADDRESS_COMPLETION_FAILED = "ADDRESS_COMPLETION_FAILED";

export function requestAddressCompletion(city, query) {
  return {
    type: ADDRESS_COMPLETION_REQUESTED,
    payload: {
      query,
    },
  };
}

export function successfullAddressCompletion(options) {
  return {
    type: ADDRESS_COMPLETION_SUCCEEDED,
    payload: {
      options,
    },
  };
}

export function failedAddressCompletion(message) {
  return {
    type: ADDRESS_COMPLETION_FAILED,
    payload: message,
  };
}
