import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { editOrder } from "../../actions/orders";
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import AddressPicker from "../AddressPicker/AddressPicker";
import AddressDetailsPicker from "../AddressDetailsPicker/AddressDetailsPicker";
import { requestAddressCompletion } from "../../actions/address";
import { fetchOrder } from "../../lib/api";
import { Order } from "../../lib/Order";
import { InvoiceRequestForm } from "../InvoiceRequestForm";

const initialEditValues = (order: Order): any => {
  return {
    first_name: order.first_name,
    last_name: order.last_name,
    phone: order.phone,
    email: order.email,
    date: moment(order.date).tz("Europe/Berlin").format("DD.MM.YYYY HH:mm"),
    customer_type: order.isPro ? "2" : "1",
    notes: order.notes,
    driver_notes: order.driver_notes,
    from: {
      id: order.from.id,
      story: order.from.story,
      elevator: order.from.elevator,
      move_type: order.from.move_type,
      hasContact: order.from.contact !== undefined,
      contactName: order.from.contact ? order.from.contact.name : "",
      contactPhone: order.from.contact ? order.from.contact.phone : "",
    },
    to: {
      id: order.to.id,
      story: order.to.story,
      elevator: order.to.elevator,
      move_type: order.to.move_type,
      hasContact: order.to.contact !== undefined,
      contactName: order.to.contact ? order.to.contact.name : "",
      contactPhone: order.to.contact ? order.to.contact.phone : "",
    },
    invoiceRequest: order.invoiceRequest,
  };
};

const updatePropsFromAddress = (address: any) => {
  const props: any = {
    id: address.id,
    story: address.story,
    elevator: address.elevator,
    move_type: address.move_type,
  };
  if (address.hasContact) {
    props["contact"] = {
      name: address.contactName,
      phone: address.contactPhone,
    };
  }
  return props;
};

const updatePropsFromState = (orderId: string, state: any) => {
  const value = {
    id: orderId,
    first_name: state.first_name,
    last_name: state.last_name,
    phone: state.phone,
    email: state.email,
    date: moment.tz(state.date, "DD.MM.YYYY HH:mm", "Europe/Berlin").format(),
    isPro: state.customer_type === "2",
    notes: state.notes,
    driver_notes: state.driver_notes,
    from: updatePropsFromAddress(state.from),
    to: updatePropsFromAddress(state.to),
    invoiceRequest: null,
  };

  // we are never able to achieve zero values because the checkbox is always set
  if (
    state.invoiceRequest &&
    Object.values(state.invoiceRequest || {}).length > 1
  ) {
    value.invoiceRequest = state.invoiceRequest;
  }

  return value;
};

export const EditOrderContainer: React.FC<{}> = () => {
  const { token, isLoadingEdit, address, error } = useSelector((state: any) => {
    return {
      token: state.authentication.token,
      isLoadingEdit: state.orders.editOrderLoading as boolean,
      address: state.address,
      error: state.orders.editOrderError,
    };
  });

  const { id: orderId } = useParams<{ id: string }>();

  const [order, setOrder] = useState<Order>();
  const [orderEdit, setOrderEdit] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadOrder = async () => {
      setIsLoading(true);
      try {
        const order = await fetchOrder(token, orderId);
        setOrder(order);
        setOrderEdit(initialEditValues(order));
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadOrder();
  }, [token, orderId]);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSaveClick = () => {
    dispatch(editOrder(token, updatePropsFromState(orderId, orderEdit)));
  };
  const navigateToDetailsPage = () => {
    history.push(`/orders/${orderId}`);
  };
  const handleAddressSearch = (query: string) => {
    dispatch(requestAddressCompletion(order?.city, query));
  };

  return (
    <div>
      {!!orderEdit && !!order && (
        <div style={{ marginBottom: "36px" }}>
          <Row className="justify-content-between">
            <Col xs="auto">
              <h2>Auftrag {order.order_number} bearbeiten</h2>
            </Col>
            <Col xs="auto">
              <ButtonGroup>
                <Button outline color="primary" onClick={handleSaveClick}>
                  Speichern
                </Button>
                <Button outline color="danger" onClick={navigateToDetailsPage}>
                  Abbrechen
                </Button>
              </ButtonGroup>
            </Col>
            <Col xs="12">
              <div className="separator" />
            </Col>
          </Row>
          {error && (
            <Row>
              <Col xs="12">
                <Alert color="danger">{JSON.stringify(error)}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs="12" sm="6">
              <Form>
                <FormGroup>
                  <Label>Vorname</Label>
                  <Input
                    type="text"
                    value={orderEdit.first_name}
                    onChange={(event) =>
                      setOrderEdit({
                        ...orderEdit,
                        first_name: event.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Nachname</Label>
                  <Input
                    type="text"
                    value={orderEdit.last_name}
                    onChange={(event) =>
                      setOrderEdit({
                        ...orderEdit,
                        last_name: event.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Telefon</Label>
                  <Input
                    type="tel"
                    value={orderEdit.phone}
                    onChange={(event) =>
                      setOrderEdit({ ...orderEdit, phone: event.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>E-Mail</Label>
                  <Input
                    type="email"
                    value={orderEdit.email}
                    onChange={(event) =>
                      setOrderEdit({ ...orderEdit, email: event.target.value })
                    }
                  />
                </FormGroup>
              </Form>
            </Col>

            <Col xs="12" sm="6">
              <Form>
                <FormGroup>
                  <Label>Zeitpunkt</Label>
                  <Input
                    type="text"
                    value={orderEdit.date}
                    onChange={(event) =>
                      setOrderEdit({ ...orderEdit, date: event.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="customerTypeSelect">Kundenart</Label>
                  <Input
                    type="select"
                    name="customerTypeSelect"
                    id="customerTypeSelect"
                    value={orderEdit.customer_type}
                    onChange={(event) => {
                      setOrderEdit({
                        ...orderEdit,
                        customer_type: event.target.value,
                      });
                    }}
                  >
                    <option value="1">Privatkunde</option>
                    <option value="2">Geschäftskunde</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="notesInput">Notizen</Label>
                  <Input
                    type="textarea"
                    name="notesInput"
                    id="notesInput"
                    value={orderEdit.notes || ""}
                    onChange={(event) => {
                      setOrderEdit({ ...orderEdit, notes: event.target.value });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="driverNotesInput">Notizen für Fahrer</Label>
                  <Input
                    type="textarea"
                    name="driverNotesInput"
                    id="driverNotesInput"
                    value={orderEdit.driver_notes || ""}
                    onChange={(event) => {
                      setOrderEdit({
                        ...setOrderEdit,
                        driver_notes: event.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h2>Abholung</h2>
              <div className="separator" />
            </Col>
          </Row>
          <AddressPicker
            initialValue={order.from.label}
            handleSearch={handleAddressSearch}
            options={address.options}
            isLoading={address.isLoading}
            onChange={(next: any) => {
              setOrderEdit({
                ...orderEdit,
                from: Object.assign({}, orderEdit.from, {
                  id: next.id,
                }),
              });
            }}
          />
          <br />
          <AddressDetailsPicker
            value={orderEdit.from}
            handleChange={(updated: any) =>
              setOrderEdit({
                ...orderEdit,
                from: Object.assign({}, orderEdit.from, updated),
              })
            }
          />
          <Row>
            <Col xs="12">
              <h2>Lieferung</h2>
              <div className="separator" />
            </Col>
          </Row>
          <AddressPicker
            initialValue={order.to.label}
            handleSearch={handleAddressSearch}
            options={address.options}
            isLoading={address.isLoading}
            onChange={(next: any) => {
              setOrderEdit({
                ...orderEdit,
                to: Object.assign({}, orderEdit.to, { id: next.id }),
              });
            }}
          />
          <br />
          <AddressDetailsPicker
            value={orderEdit.to}
            handleChange={(updated: any) =>
              setOrderEdit({
                ...orderEdit,
                to: Object.assign({}, orderEdit.to, updated),
              })
            }
          />
          <Row>
            <Col xs="12">
              <h2>Rechnung</h2>
              <div className="separator" />
            </Col>
          </Row>
          <InvoiceRequestForm
            value={orderEdit.invoiceRequest}
            handleChange={(updated) =>
              setOrderEdit((current: any) => ({
                ...current,
                invoiceRequest: { ...current.invoiceRequest, ...updated },
              }))
            }
          />
        </div>
      )}
      <Modal
        isOpen={isLoading || isLoadingEdit}
        toggle={() => {
          console.log("CLOSE");
        }}
      >
        <ModalHeader>Laden...</ModalHeader>
      </Modal>
    </div>
  );
};
