import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class HomeContainer extends Component {
  render() {
    return (
      <div>
        <h2>Startseite</h2>
        <p>Willkommen beim Movemates Customer Support Center.</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
