import { ADDRESS_COMPLETION_REQUESTED, ADDRESS_COMPLETION_SUCCEEDED, ADDRESS_COMPLETION_FAILED } from '../actions/address'

export default function address(state={options: [], isLoading: false}, action) {
    switch (action.type) {
        case ADDRESS_COMPLETION_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true
            })
        case ADDRESS_COMPLETION_SUCCEEDED:
            return Object.assign({}, state, {
                options: action.payload.options,
                isLoading: false
            })
        case ADDRESS_COMPLETION_FAILED:
            alert(action.payload)
            return Object.assign({}, state, {
                isLoading: false
            })
        default:
            return state
    }
}