export enum MoveType {
  DriveOnly = "drive_only",
  OneHelper = "move_one_helper",
  TwoHelpers = "move_two_helpers",
}

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface ContactInfo {
  name: string;
  phone: string;
}

export interface Address {
  id: string;
  story: number;
  elevator: boolean;
  move_type: MoveType;
  label: string;
  street: string;
  postalCode: string;
  city: string;
  country?: string;
  houseNumber: string;
  state?: string;
  county?: string;
  district?: string;
  location: Coordinates;
  additionalData: any[];
  contact?: ContactInfo;
}

export enum OrderStatusType {
  Unconfirmed = "unconfirmed",
  AwaitingCharge = "charge.pending",
  PaymentFailed = "charge.failed",
  PaymentCancelled = "charge.cancelled",
  Pending = "pending",
  Accepted = "driver.accepted",
  InProgress = "driver.in_progress",
  Finished = "driver.finished",
  Cancelled = "cancelled",
  Expired = "expired",
  RefundedCustomer = "customer.refunded",
}

export interface Price {
  customer: number;
  driver: number;
  exclTax: number;
}

export interface Estimation {
  ride_time: number;
  handling_time_from: number;
  handling_time_to: number;
}

export interface Item {
  id: string;
  name: string;
  quantity: number;
  two_people: boolean;
}

export interface CustomItem {
  width: number;
  height: number;
  depth: number;
  weight: number;
  quantity: number;
  notes: string;
}

export interface InvoiceRequest {
  companyName?: string;
  name: string;
  street: string;
  houseNumber: string;
  city: string;
  zip: string;
  shouldBeEmailedAutomatically: boolean;
}

export interface Order {
  id: string;
  driver: string;
  price: Price;
  estimation: Estimation;
  state: string;
  city: string;
  items: Item[];
  custom_items: CustomItem[];
  date: Date;
  createdAt: Date;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  notes: string;
  driver_notes: string;
  state_type?: OrderStatusType;
  source_id?: string;
  charge_id?: string;
  transfer_id?: string;
  transaction_id?: string;
  paymentMethod?: string;
  order_number: string;
  should_expire: boolean;
  isPro: boolean;
  from: Address;
  to: Address;
  invoiceRequest?: InvoiceRequest;
  invoiceUrl?: string;
}
