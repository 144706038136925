import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { requestAccountInfo } from "../../actions/authentication"
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap"

class SettingsContainer extends Component {
  render() {
    return (
      <div>
        <h2>Einstellungen</h2>
        {this.props.user ? (
            <ListGroup>
                <ListGroupItem>
                    <ListGroupItemHeading>Name</ListGroupItemHeading>
                    <ListGroupItemText>{this.props.user.name}</ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem>
                    <ListGroupItemHeading>E-Mail</ListGroupItemHeading>
                    <ListGroupItemText>{this.props.user.email}</ListGroupItemText>
                </ListGroupItem>
            </ListGroup>
        ) : (
            <h3>Loading...</h3>   
        )}
      </div>
    );
  }
  componentDidMount() {
      this.props.requestAccountInfo(this.props.token)
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    user: state.authentication.accountInfo
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      requestAccountInfo
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)
