import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Table,
  Modal,
  ModalHeader,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import { useSelector } from "react-redux";
import { usePagedApi } from "../../lib/usePagedApi";
import { useHistory } from "react-router-dom";
import { Order } from "../../lib/Order";
import {
  confirmed_forthcoming_types,
  finished_types,
} from "../../lib/orderStates";

export const filteredOrders = (allOrders: Order[], filters: number[]) => {
  // 1 means include finished orders
  // 2 means include accepted and pending orders
  // 3 means include all orders
  // 4 means there is no stripe charge for the order
  var filteredFirstStep;
  if (filters.includes(3)) {
    filteredFirstStep = Array.from(allOrders);
  } else {
    const includeTypes = (filters.includes(1) ? finished_types : []).concat(
      filters.includes(2) ? confirmed_forthcoming_types : []
    );
    filteredFirstStep = allOrders.filter((o) =>
      includeTypes.includes(o.state_type || "")
    );
  }
  const filteredSecondStep = filters.includes(4)
    ? filteredFirstStep.filter((o) => !o.charge_id)
    : filteredFirstStep;
  return filteredSecondStep;
};

// orders.sort((lhs, rhs) => {
//   return moment(lhs.date).tz("Europe/Berlin").isBefore(rhs.date) ? 1 : -1;
// });

export const OrdersListContainer: React.FC<{}> = () => {
  const token = useSelector((state: any) => {
    return state.authentication.token;
  });

  const [
    { data: orders, isLoading, isLoadingMore, cursor, error },
    ,
    ,
    loadMore,
  ] = usePagedApi<Order>(token, "admins/orders");

  useEffect(() => {
    if (!!error) console.error(error);
  }, [error]);

  const [filters, setFilters] = useState([1, 2]);

  const handleFilterToggle = (filter: number) => {
    setFilters(
      filters.includes(filter)
        ? filters.filter((i) => i !== filter)
        : [...filters, filter]
    );
  };

  const history = useHistory();

  const handleOrderClicked = (order: any) => {
    history.push(`/orders/${order.id}`);
  };

  const filtered = filteredOrders(orders, filters);

  return (
    <div>
      <Row className="justify-content-between">
        <Col xs="auto">
          <h2>Aufträge</h2>
        </Col>
        <Col xs="auto">
          {/* <ButtonGroup style={{ marginRight: "10px" }}>
            <Button
              color="secondary"
              onClick={() => setUrlPath("admins/orders")}
            >
              Neu Laden
            </Button>
          </ButtonGroup> */}
          <ButtonGroup>
            <Button
              color="primary"
              onClick={() => handleFilterToggle(1)}
              active={filters.includes(1)}
            >
              Abgeschlossene
            </Button>
            <Button
              color="primary"
              onClick={() => handleFilterToggle(2)}
              active={filters.includes(2)}
            >
              Anstehende
            </Button>
            <Button
              color="primary"
              onClick={() => handleFilterToggle(3)}
              active={filters.includes(3)}
            >
              Alle
            </Button>
            <Button
              color="primary"
              onClick={() => handleFilterToggle(4)}
              active={filters.includes(4)}
            >
              Nur extern bezahlte
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Table hover responsive>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Name</th>
              <th>Status</th>
              <th>Stadt</th>
              <th>Nummer</th>
              <th>Preis</th>
              <th>Charged</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((order) => (
              <tr
                onClick={() => handleOrderClicked(order)}
                key={order.id}
                className="clickable"
              >
                <td>
                  {moment(order.date).tz("Europe/Berlin").format("DD.MM.YYYY")}
                </td>
                <td>
                  {moment(order.date).tz("Europe/Berlin").format("HH:mm")}Uhr
                </td>
                <td>{`${order.first_name} ${order.last_name}`}</td>
                <td>{order.state_type}</td>
                <td>{order.city}</td>
                <td>{order.order_number}</td>
                <td>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(order.price.customer / 100)}
                </td>
                <td>{order.charge_id || order.transaction_id ? "✅" : "❌"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      {cursor && (
        <Row>
          <Col style={{ textAlign: "center" }}>
            <Button onClick={loadMore} outline color="secondary">
              Mehr laden...
            </Button>
          </Col>
        </Row>
      )}
      <Row className="justify-content-between">
        <Col>
          <span>Anzahl: {filtered.length}</span>
        </Col>
      </Row>
      <Modal
        isOpen={isLoading || isLoadingMore}
        toggle={() => {
          console.log("CLOSE");
        }}
      >
        <ModalHeader>Laden...</ModalHeader>
      </Modal>
    </div>
  );
};
