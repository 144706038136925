import { useState, useEffect, useReducer, Reducer } from "react";
import { fetchPage } from "./api";
import { Page } from "./page";

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  data: [],
};

type State<T> = {
  data: T[];
  isLoading: boolean;
  error?: any;
  cursor?: string;
  isLoadingMore: boolean;
};

type Action =
  | { type: "FETCH_START" }
  | { type: "FETCH_SUCCESS"; page: Page<any> }
  | { type: "FETCH_FAILURE"; error: any }
  | { type: "FETCH_MORE_START" }
  | { type: "FETCH_MORE_SUCCESS"; page: Page<any> }
  | { type: "FETCH_MORE_FAILURE"; error: any };

const reducer = <T>(state: State<T>, action: Action): State<T> => {
  switch (action.type) {
    case "FETCH_START":
      return { ...state, isLoading: true, error: undefined };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        data: action.page.results,
        cursor: action.page.cursors?.after,
        error: undefined,
      };
    case "FETCH_FAILURE":
    case "FETCH_MORE_FAILURE":
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        error: action.error,
      };
    case "FETCH_MORE_START":
      return { ...state, isLoadingMore: true, error: undefined };
    case "FETCH_MORE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        data: [...state.data, ...action.page.results],
        cursor: action.page.cursors?.after,
        error: undefined,
      };
    default:
      return state;
  }
};

export const usePagedApi = <T>(
  token: string,
  initialPath?: string,
  initialParams: any = {}
) => {
  const [urlPath, setUrlPath] = useState(initialPath);
  const [urlParams, setUrlParams] = useState(initialParams);

  const [state, dispatch] = useReducer<Reducer<State<T>, Action>>(
    reducer,
    initialState
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!urlPath) return;
      dispatch({ type: "FETCH_START" });
      try {
        const page = await fetchPage<T>(token, urlPath);
        dispatch({ type: "FETCH_SUCCESS", page });
      } catch (error) {
        dispatch({ type: "FETCH_FAILURE", error });
      }
    };
    fetchData();
  }, [urlPath, urlParams, token]);

  const loadMore = async () => {
    if (!urlPath) return;
    dispatch({ type: "FETCH_MORE_START" });
    try {
      const page = await fetchPage<T>(token, urlPath, state.cursor);
      dispatch({ type: "FETCH_MORE_SUCCESS", page });
    } catch (error) {
      dispatch({ type: "FETCH_MORE_FAILURE", error });
    }
  };

  return [state, setUrlPath, setUrlParams, loadMore] as const;
};
