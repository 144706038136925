import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, Modal, ModalHeader } from "reactstrap";
import { withRouter } from "react-router-dom";
import { requestDriverList } from "../../actions/drivers";

class DriverListContainer extends Component {
  constructor(props) {
    super(props);

    this.handleDriverClicked = this.handleDriverClicked.bind(this);
  }
  render() {
    return (
      <div>
        <h2>Fahrer</h2>
        {this.props.drivers ? (
          <Table hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>E-Mail</th>
                <th>Telefon</th>
                <th>Stadt</th>
              </tr>
            </thead>
            <tbody>
              {this.props.drivers.map(driver => (
                <tr
                  onClick={() => this.handleDriverClicked(driver)}
                  key={driver.id}
                  className="clickable"
                >
                  <td>{driver.name}</td>
                  <td>{driver.email}</td>
                  <td>{driver.phone}</td>
                  <td>{driver.city}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <span>Lade...</span>
        )}
        <Modal
          isOpen={this.props.isLoading}
          toggle={() => {
            console.log("CLOSE");
          }}
        >
          <ModalHeader>Laden...</ModalHeader>
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    this.props.requestDriverList(this.props.token);
  }
  handleDriverClicked(driver) {
    this.props.history.push(`/drivers/${driver.id}`);
  }
}

const mapStateToProps = state => {
  return {
    token: state.authentication.token,
    drivers: state.drivers.driverList,
    isLoading: state.drivers.driverListLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      requestDriverList
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DriverListContainer)
);
