import { fetchPage } from "./api";
import { Page } from "./page";

export async function unpageApi<T>(
  token: string,
  urlPath: string
): Promise<T[]> {
  let accumulated = new Array<T>();
  let cursor: string | undefined;
  let latestPage: Page<T>;

  do {
    latestPage = await fetchPage(token, urlPath, cursor);

    cursor = latestPage.cursors?.after;
    accumulated = [...accumulated, ...latestPage.results];
  } while (latestPage.results.length && latestPage.cursors?.after);
  return accumulated;
}
